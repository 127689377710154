export default {
  redir: (queries, optsSet) => {

    const qrIndexs = {
      1: {
        link: 'https://youtu.be/0Bq-MUlXBEU',
        parts: {
          2: 77, 3: 150
        }
      },
      2: {
        link: 'https://youtu.be/vtzUKYPjNi4'
      },
      3: {
        link: 'https://youtu.be/xf66jndQaCQ'
      },
      4: {
        link: 'https://youtu.be/KfrZIw2ZSDU',
        parts: {
          2: 158, 3: 243
        }
      },
      5: {
        link: 'https://youtu.be/UhJVi3RkNVI'
      },
      6: {
        link: 'https://youtu.be/NO3eT-D57dk'
      },
      7: {
        link: 'https://youtu.be/x0whoUnr4GQ'
      },
      8: {
        link: 'https://youtu.be/kGyLeSUHApA',
        // parts: {
        //   2: 125, 3: 147
        // }
      },
      9: {
        link: 'https://youtu.be/OvmU2TvmH3U'
      },
      10: {
        link: 'https://youtu.be/dcIFBIS2Y0o'
      },
      11: {
        link: 'https://youtu.be/5AvIxS_OCMA',
        parts: {
          2: 70, 3: 223
        }
      },
      12: {
        link: 'https://youtu.be/0BywV8DlIuE'
      },
      13: {
        link: 'https://youtu.be/UELeii9Jk7c'
      },
      14: {
        link: 'https://youtu.be/4LPXmOdh0Ho',
        parts: {
          2: 272, 
          3: 111,
          4: 199,
          5: 329
        }
      },
      15: {
        title: '⭐️ 프론트엔드 주제 영상 전, 배경지식들을 먼저 시청하실 것을 권장합니다.',
        opts: [
          {
            title: '📕  배경지식 1. HTML, CSS, 자바스크립트',
            link: 'https://youtu.be/ffENjt7aEdc'
          },
          {
            title: '📗  배경지식 2. 정적 웹과 동적 웹',
            link: 'https://youtu.be/C06xRvXIAUk'
          },
          {
            title: '📘  배경지식 3. MVC 프레임워크',
            link: 'https://youtu.be/AERY1ZGoYc8'
          },
          {
            title: '💻  프론트엔드 개발 방식',
            link: 'https://youtu.be/q-S-0pqtYi8'
          },
        ]
      },
      16: {
        title: '프레임워크 관련 영상',
        opts: [
          {
            title: '📚 라이브러리와 프레임워크',
            link: 'https://youtu.be/FlWEA7A09M8'
          },
          {
            title: '예시 1: MVC 프레임워크',
            link: 'https://youtu.be/AERY1ZGoYc8'
          },
          {
            title: '예시 2: Vue (프론트엔드 프레임워크)',
            link: 'https://youtu.be/hy3mji0XuMw'
          },
        ]
      },
      17: {
        link: 'https://youtu.be/V2X_vTZtDUc'
      },
      18: {
        link: 'https://youtu.be/n6uIsU4XAL8'
      },
      19: {
        link: 'https://youtu.be/IvRPDSJ2oIM'
      },
      20: {
        link: 'https://youtu.be/DU5jqGcJ9J4'
      },
      21: {
        link: 'https://youtu.be/ccCV2w8MYzM'
      },
      22: {
        link: 'https://youtu.be/4B8lHA8kapc'
      },
      23: {
        title: '데이터베이스, DBMS, 관계형 데이터베이스 공통 영상',
        opts: [
          {
            title: '🐬  데이터베이스, DBMS, RDBMS',
            link: 'https://youtu.be/fEdRp8zHfTs'
          },
          {
            title: '📚  MySQL 강좌 전체 보기',
            link: 'https://youtu.be/dgpBXNa9vJc'
          },
        ]
      },
      24: {
        link: 'https://youtu.be/Am4IcxUhBYY'
      },
      25: {
        title: '리눅스',
        opts: [
          {
            title: '🐧 리눅스 소개',
            link: 'https://youtu.be/AUnqSWyJSjE'
          },
          {
            title: '📚  리눅스 강좌 전체',
            link: 'https://youtu.be/tPWBF13JIVk'
          },
        ]
      },
      26: {
        link: 'https://youtu.be/Vy-kF1uR7O0',
        parts: {
          2: 73, 3: 166
        }
      },
      27: {
        link: 'https://youtu.be/AEJr3Nwz0IQ'
      },
      28: {
        link: 'https://youtu.be/RBQAqh3Z1eE'
      },
      29: {
        link: 'https://youtu.be/GFiAuXC18w0'
      },
      30: {
        link: 'https://youtu.be/nYZSTadLKEM'
      },
      31: {
        link: 'https://youtu.be/OUzfGUzaIKw'
      },
      32: {
        link: 'https://youtu.be/Y3LjiXy3fJ0'
      },
      33: {
        link: 'https://youtu.be/IdFyuTbf-G0'
      },
      34: {
        link: 'https://youtu.be/FWZ12QprWgU',
      },
      35: {
        link: 'https://youtu.be/Irc80mcgdvQ'
      },
      36: {
        link: 'https://youtu.be/ZW848zXPg58'
      },
      37: {
        link: 'https://youtu.be/IkVKrJP-98Y'
      },
      38: {
        link: 'https://youtu.be/RaxWd2UjLXI',
        parts: {
          2: 405
        }
      },
      39: {
        link: 'https://youtu.be/8eglWFOYwVI'
      },

      40: {
        title: 'Git 관련 영상',
        opts: [
          {
            title: 'VCS, 깃, 깃 호스팅 서비스',
            link: 'https://youtu.be/-jydcJRo5hA'
          },
          {
            title: 'Git 맛보기 강좌 [상] - Git',
            link: 'https://youtu.be/FXDjmsiv8fI'
          },
          {
            title: 'Git 맛보기 강좌 [하] - GitHub',
            link: 'https://youtu.be/GaKjTjwcKQo'
          },
          {
            title: '제대로 파는 Git & GitHub 강좌',
            link: 'https://youtu.be/1I3hMwQU6GU'
          },
        ]
      },
    }

    if (queries.hongong_chapter) {
      if (qrIndexs[queries.hongong_chapter]) {
        const qr = qrIndexs[queries.hongong_chapter]
        let suffix = ''

        if (
          queries.part 
          && qr.parts 
          && qr.parts[queries.part]
          ) {
            suffix = `?t=${qr.parts[queries.part]}`
        }

        qr.link && window.location.replace(`${qr.link}${suffix}`)
        qr.title && optsSet({
          title: qr.title,
          opts: qr.opts
        })

      } else {
        window.location.replace('/movie-temp')
      }
    }
  }
}