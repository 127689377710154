export default {
  redir: (queries, optsSet) => {

    const hongongOpt_mysql = {
        title: '데이터베이스, DBMS, 관계형 데이터베이스 공통 영상',
        opts: [
          {
            title: '🐬  데이터베이스, DBMS, RDBMS',
            link: 'https://youtu.be/fEdRp8zHfTs'
          },
          {
            title: '📚  MySQL 강좌 전체 보기',
            link: 'https://youtu.be/dgpBXNa9vJc'
          },
        ]
      }

    const hongongOpt_git = {
        title: 'Git 관련 영상',
        opts: [
          {
            title: 'VCS, 깃, 깃 호스팅 서비스',
            link: 'https://youtu.be/-jydcJRo5hA'
          },
          {
            title: 'Git 맛보기 강좌 [상] - Git',
            link: 'https://youtu.be/FXDjmsiv8fI'
          },
          {
            title: 'Git 맛보기 강좌 [하] - GitHub',
            link: 'https://youtu.be/GaKjTjwcKQo'
          },
          {
            title: '제대로 파는 Git & GitHub 강좌',
            link: 'https://youtu.be/1I3hMwQU6GU'
          },
        ]
      }

    const hongongOpts = {
      'GYmuQJiPeM4': {
        title: '영상으로 시청하실 주제를 선택하세요',
        opts: [
          {
            title: '🖥  디버깅 / 빌드 / 배포',
            link: 'https://youtu.be/KfrZIw2ZSDU'
          },
          {
            title: '🎙  컴파일러 vs 인터프리터',
            link: 'https://youtu.be/nYZSTadLKEM'
          },
          {
            title: '📝  환경변수',
            link: 'https://youtu.be/IkVKrJP-98Y'
          },
        ]
      },
      'C06xRvXIAUk': {
        title: '⭐️ 프론트엔드 주제 영상 전, 배경지식들을 먼저 시청하실 것을 권장합니다.',
        opts: [
          {
            title: '📕  배경지식 1. HTML, CSS, 자바스크립트',
            link: 'https://youtu.be/ffENjt7aEdc'
          },
          {
            title: '📗  배경지식 2. 정적 웹과 동적 웹',
            link: 'https://youtu.be/C06xRvXIAUk'
          },
          {
            title: '📘  배경지식 3. MVC 프레임워크',
            link: 'https://youtu.be/AERY1ZGoYc8'
          },
          {
            title: '💻  프론트엔드 개발 방식',
            link: 'https://youtu.be/q-S-0pqtYi8'
          },
        ]
      },
      'AERY1ZGoYc8': {
        title: '프레임워크 관련 영상',
        opts: [
          {
            title: '📚 라이브러리와 프레임워크',
            link: 'https://youtu.be/8eglWFOYwVI'
          },
          {
            title: '예시 1: MVC 프레임워크',
            link: 'https://youtu.be/AERY1ZGoYc8'
          },
          {
            title: '예시 2: Vue (프론트엔드 프레임워크)',
            link: 'https://youtu.be/hy3mji0XuMw'
          },
        ]
      },
      'tPWBF13JIVk': {
        title: '리눅스',
        opts: [
          {
            title: '🐧 리눅스 소개',
            link: 'https://youtu.be/AUnqSWyJSjE'
          },
          {
            title: '📚  리눅스 강좌 전체',
            link: 'https://youtu.be/tPWBF13JIVk'
          },
        ]
      },
      'dgpBXNa9vJc': hongongOpt_mysql,
      'NmraFRrusD8': hongongOpt_mysql,
      'Bd35Ze7-dIw': hongongOpt_git,
      'FXDjmsiv8fI': hongongOpt_git,
      '1I3hMwQU6GU': hongongOpt_git,
    }

    const hongongAddMap = {
      // 고수준 언어 vs 저수준 언어
      '-1': 'https://youtu.be/vtzUKYPjNi4',
      // 코딩 vs 개발 vs 프로그래밍
      '-11': 'https://youtu.be/0Bq-MUlXBEU',
      // 코드 에디터 vs IDE
      '-12': 'https://youtu.be/xf66jndQaCQ',
      // 클라우드 서비스의 종류
      '-5': 'https://youtu.be/x0whoUnr4GQ',
      // 반응형 vs 적응형
      '-6': 'https://youtu.be/dcIFBIS2Y0o',
      // 응답 코드
      '-7': 'https://youtu.be/4B8lHA8kapc',
      // NoSQL
      '-2': 'https://youtu.be/Am4IcxUhBYY',
      // CPU, 메모리
      '-3': 'https://youtu.be/Vy-kF1uR7O0',
      // 자료형
      '-8': 'https://youtu.be/RBQAqh3Z1eE',
      // 오류의 종류
      '-4': 'https://youtu.be/FWZ12QprWgU',
      // 런타임환경과 SDK
      '-9': 'https://youtu.be/RaxWd2UjLXI',
      // 라이브러리, 프레임워크
      '-10': 'https://youtu.be/8eglWFOYwVI',
    }

    const yalcoMap = {
      '기존코드': '바꿀코드',
      // 서버와 클라이언트
      'R0YJ-r-qLNE': 'UhJVi3RkNVI',
      // 클라우드 컴퓨팅
      'IH7mUwunzlo': 'NO3eT-D57dk',
      // HTML & CSS & 자바스크립트
      'ffENjt7aEdc': 'kGyLeSUHApA',
      // 웹 표준
      'T8r-6mMlzWg': 'OvmU2TvmH3U',
      // 쿠키 vs 세션 vs 캐시
      'OpoVuwxGRDI': '5AvIxS_OCMA',
      // 토큰
      '1QiOXWEbqYQ': '0BywV8DlIuE',
      // CDN
      '_kcoeK0ITkQ': 'UELeii9Jk7c',
      // 모바일 앱의 여러 종류들
      'NMdnzvPsGu8': '4LPXmOdh0Ho',
      // API
      'iOueE9AXDQQ': 'V2X_vTZtDUc',
      // XML, JSON 그리고 YAML
      '55FrHTNjTCc': 'n6uIsU4XAL8',
      // IP 주소
      'GK3h936Co-k': 'IvRPDSJ2oIM',
      // 도메인과 DNS
      '6fc9NAQkcv0': 'DU5jqGcJ9J4',
      // HTTPS
      'H6lpFRpyl14': 'ccCV2w8MYzM',
      // 프로세스와 스레드 
      'iks_Xb9DtTM': 'AEJr3Nwz0IQ',
      // 문자 인코딩, 유니코드
      '1jo6q4dihoU': 'GFiAuXC18w0',
      // 객체지향
      'vrhIxBWSJ04': 'OUzfGUzaIKw',
      // 함수형 
      'jVG5jvOzu9Y': 'Y3LjiXy3fJ0',
      // 매니지드 언매니지드
      'u65F4ECaKaY': 'IdFyuTbf-G0',
      // 예외 처리
      'LQ182IQZfW8': 'Irc80mcgdvQ',
      // 메모리 누수, 가비지 컬렉션
      '24f2-eJAeII': 'ZW848zXPg58',
      // 런타임 환경과 SDK 
      'VvVruEDCSSY': 'fL5hPQJ4AxM',

    }

    if (queries.yt) {

      // 다중 선택지에 포함되는지 확인
      let inHongongOpts = false;
      Object.keys(hongongOpts).forEach((key) => {
        if (
          key === queries.yt 
          || (queries.yt.includes('hongong-temp') && queries.yt.endsWith(key))
          ) {
          inHongongOpts = true
          optsSet({
            title: hongongOpts[key].title,
            opts: hongongOpts[key].opts
          })
        }
      })

      if (inHongongOpts) return

      // 추가영상에 포함되는지 확인
      let inHongongAddMap = false
      if (queries.yt.includes('hongong-temp')) {
        Object.keys(hongongAddMap).forEach((key) => {
          if (queries.yt.endsWith(key)) {
            window.location.replace(hongongAddMap[key])
            inHongongAddMap = true
            return
          }
        })

        if (inHongongAddMap) return

        window.location.replace('/movie-temp')
        return
      }

      // 기존영상에서 새 영상으로
      let code = queries.yt

      Object.keys(yalcoMap).forEach((key) => {
        code = code
        .replace(key, yalcoMap[key])
      })

      window.location.replace('https://youtu.be/' + code)
    }
  }
}