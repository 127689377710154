import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"
import queryString from "query-string"

import hongongYalco1st from "../redirs/hongong-yalco-1st"
import hongongYalco2nd from "../redirs/hongong-yalco-2nd"
import hongongYalco3rd from "../redirs/hongong-yalco-3rd"

import "../components/css/page/redir.css"

class RedirPage extends React.Component {

  state = {
    optsTitle: '⌛️  링크로 이동중입니다...',
    opts: [
    ]
  }

  render () {
    return (
      <Layout page="book">
        <SEO title="혼공얄코"/>
        <section className="redir">
          <h1 className="redir__title">{this.state.optsTitle}</h1>
          <ul>
            { this.state.opts.map((opt, key) => (
              <li key={key} className="redir__opt-item">
                <a href={opt.link}>
                  { opt.title }
                </a>
              </li>
            )) }
          </ul>
        </section>
      </Layout>
    )
  }

  componentDidMount () {
    const queries = queryString.parse(window.location.search)

    const editions = [
      hongongYalco1st,
      hongongYalco2nd,
      hongongYalco3rd
    ]
    
    editions && editions.forEach(hongongYalco => {
      hongongYalco.redir(queries, (optsParam) => {
        this.setState(() => {
          return {
            optsTitle: optsParam.title,
            opts:optsParam.opts 
          }
        })
      })
    });
  }
}

export default RedirPage