export default {
  redir: (queries, optsSet) => {

    const qrIndexs = {
      _1_1: {
        link: 'https://youtu.be/_DKQiJG6CmY?si=IXn2zca-xotc3Izj'
      },
      _1_2: {
        link: 'https://youtu.be/_DKQiJG6CmY?si=tSBSA6wU0_pfLkuQ&t=187'
      },
      _1_3: {
        link: 'https://youtu.be/_DKQiJG6CmY?si=StFuXwt-KknZ051q&t=362'
      },
      _1_4: {
        link: 'https://youtu.be/_DKQiJG6CmY?si=HH-AxCKVgnZt2tu_&t=558'
      },
      _1_5: {
        link: 'https://youtu.be/_DKQiJG6CmY?si=FLkUJVQcILkn0lIy&t=761'
      },
      _2_1: {
        link: 'https://youtu.be/BuvKaLD3Ec4?si=muui6zqVz6Eoo3vo'
      },
      _2_3: {
        link: 'https://youtu.be/BuvKaLD3Ec4?si=XEx7lKr4klcE2Wzm&t=208'
      },
      _2_4: {
        link: 'https://youtu.be/BuvKaLD3Ec4?si=OO9hxYYy-rtqXiRM&t=402'
      },
      _2_5: {
        link: 'https://youtu.be/BuvKaLD3Ec4?si=8t_TtXcxNImFi1z_&t=612'
      },
      _2_7: {
        link: 'https://youtu.be/BuvKaLD3Ec4?si=hLwUOZ6T5ma5DXMT&t=817'
      },
    }

    if (queries.hongong_ai) {
      if (qrIndexs[queries.hongong_ai]) {
        const qr = qrIndexs[queries.hongong_ai]
        console.log(qr)
        qr.link && window.location.replace(`${qr.link}`)
      } else {
        // window.location.replace('/movie-temp')
      }
    }
  }
}